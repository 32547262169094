import React, { FC, useContext } from "react";
import styled from "styled-components";
import { breakpoints } from "@theme/breakpoints";
import { AnimatedText } from "@components/atoms/AnimatedText";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Dots } from "@components/atoms/Dots";
import { Button } from "@components/atoms/Button";
import { MainContext } from "@context";
import {
  GatsbyImage,
  getImageData,
  IGatsbyImageData,
} from "gatsby-plugin-image";

const StyledWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  overflow: hidden;

  ${breakpoints.tabletMax`
    padding-top: 110px;
    min-height: 1000px;
  `}

  ${breakpoints.phoneMax`
    padding-top: 90px;
    min-height: 750px;
  `}
`;

const StyledContainer = styled.div`
  width: 100%;
  padding: 50px 0;
  position: relative;
  display: flex;
  justify-content: center;
`;

const StyledInnerWrapper = styled.div`
  max-width: 1300px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  position: relative;

  @media (max-width: 1280px) {
    width: 90%;
  }

  ${breakpoints.tabletMax`
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
  `}
`;

const StyledDots = styled(Dots)`
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const StyledLeftWrapper = styled.div`
  flex: 1;
  max-width: 540px;

  ${breakpoints.tabletMax`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

const StyledVectorWrapper = styled.div`
  width: 538px;
  height: auto;
  margin-left: 50px;

  ${breakpoints.laptopMax`
    width: 438px;
    height: auto;
  `};

  ${breakpoints.phoneMax`
    max-width: 300px;
    width: 100%;
  `}

  ${breakpoints.tabletMax`
    margin-left: 0;
  `}

  > * {
    width: 100%;
    height: 100%;
  }
`;

const StyledHeadline = styled.h1`
  font-size: 4.8rem;
  font-family: "Noto Sans", sans-serif;

  ${breakpoints.tabletMax`
    text-align: center;
  `}

  ${breakpoints.phoneMax`
    font-size: 2.6rem;
  `}
`;

const StyledParagraph = styled.p`
  line-height: 1.6;
  margin: 0 0 40px;
  width: 100%;
  position: relative;
  padding: 10px 0 10px 30px;

  span {
    font-weight: 600;
  }

  ::before {
    position: absolute;
    left: 0;
    top: 0;
    background: ${({ theme }) => theme.primary};
    width: 2px;
    height: 100%;
    content: "";
    display: block;
  }

  ${breakpoints.phoneMax`
    font-size: 1.4rem;
    padding: 0;
    max-width: 430px;
    
    ::before {
      display: none;
    }
  `}
`;

const StyledButton = styled(Button)`
  ${breakpoints.tabletMax`
    display: none;
  `}
`;

export const SecondaryHero: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { scroller } = useContext(MainContext);

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledInnerWrapper>
          <StyledLeftWrapper>
            <StyledHeadline>
              <AnimatedText start duration={0.5}>
                {props.title}
              </AnimatedText>
            </StyledHeadline>

            <StyledParagraph
              dangerouslySetInnerHTML={{
                __html: props.description,
              }}
            />

            {props.button && (
              <StyledButton
                secondary
                onClick={() => {
                  const el = document.querySelector("#contact");
                  if (!el) return;
                  scroller?.toElement(el);
                }}
              >
                {props.button}
              </StyledButton>
            )}
          </StyledLeftWrapper>
          <StyledVectorWrapper>
            {props.variant === "image" ? (
              <GatsbyImage
                alt={props.title}
                image={props.image}
                objectFit="contain"
              />
            ) : (
              props.vector({})
            )}
          </StyledVectorWrapper>
        </StyledInnerWrapper>
        <StyledDots />
      </StyledContainer>
    </StyledWrapper>
  );
};

type VariantVector = {
  variant?: "vector";
  vector: FC;
};

type VariantImage = {
  variant?: "image";
  image: IGatsbyImageData;
};

type Props = {
  title: string;
  description: string;
  button?: string;
} & (VariantVector | VariantImage);
