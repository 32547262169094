import React from "react";
import { BasicTemplate } from "@templates/BasicTemplate";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { SecondaryHero } from "@components/molecules/SecondaryHero";
import { ReviewsSection } from "@components/molecules/ReviewsSection";
import { Contact } from "@components/molecules/Contact";
import { OurMission } from "@components/molecules/OurMission";
import { ReactComponent as Vector } from "@vectors/about-us-hero.svg";
import { CEO } from "@components/molecules/CEO";

import { graphql, PageProps } from "gatsby";
import { WhyUsSection } from "@components/molecules/WhyUsSection";
import { IGatsbyImageData } from "gatsby-plugin-image";
import HtmlParser from "react-html-parser";

const AboutUs = (props: PageProps<Data>) => {
  const { t } = useTranslation("common");

  const { language } = useI18next();
  let description: string;

  if (language === "en") {
    description =
      props.data.strapiAboutUs.heroDescriptionEN.data.heroDescriptionEN;
  } else if (language === "de") {
    description =
      props.data.strapiAboutUs.heroDescriptionDE.data.heroDescriptionDE;
  } else {
    description = props.data.strapiAboutUs.heroDescription.data.heroDescription;
  }

  return (
    <BasicTemplate
      title={t("title-about-us")}
      description={description}
      renderHero={() => (
        <SecondaryHero
          variant="image"
          image={
            props.data.strapiAboutUs.heroPicture.localFile.childImageSharp
              .gatsbyImageData
          }
          title={t("hero:title-about-us")}
          description={description.replace("<p>", "").replace("</p>", "")}
          button={t("hero:button")}
        />
      )}
      isAnotherThanIndex
    >
      <OurMission />
      {/*<HowWeCanHelp />*/}
      {/*<HowWeWork />*/}
      <CEO />
      <WhyUsSection />
      <ReviewsSection secondary />
      <Contact />
    </BasicTemplate>
  );
};

interface Data {
  locales: unknown;
  strapiAboutUs: {
    heroDescription: {
      data: {
        heroDescription: string;
      };
    };
    heroDescriptionDE: {
      data: {
        heroDescriptionDE: string;
      };
    };
    heroDescriptionEN: {
      data: {
        heroDescriptionEN: string;
      };
    };
    heroPicture: {
      localFile: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
    };
  };
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    strapiAboutUs {
      heroDescription {
        data {
          heroDescription
        }
      }

      heroDescriptionDE {
        data {
          heroDescriptionDE
        }
      }

      heroDescriptionEN {
        data {
          heroDescriptionEN
        }
      }

      heroPicture {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80)
          }
        }
      }
    }
  }
`;

export default AboutUs;
