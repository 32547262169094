import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import { SimpleFileQuery } from "@interfaces/childImageSharp";
import Quote from "@components/atoms/Quote";
import { Button } from "@components/atoms/Button";
import { breakpoints } from "@theme/breakpoints";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Html from "react-html-parser";

const StyledWrapper = styled.section`
  margin-top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1542px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  ${breakpoints.tabletMax`
    flex-direction: column;
    margin-top: 140px;
  `}

  ${breakpoints.phoneMax`
    margin-top: 80px;
  `}
`;

const StyledImageWrapper = styled.figure`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0;
  margin-right: 180px;

  figcaption {
    text-align: center;

    h2 {
      font-size: 4.8rem;
      font-weight: 700;
      margin: 50px 0 15px;
    }

    p {
      font-size: 3.2rem;
      font-weight: 500;
      margin: 0;
      color: ${({ theme }) => theme.washMedium};

      b {
        font-weight: 600;
        color: ${({ theme }) => theme.primary};
      }
    }
  }

  ${breakpoints.laptopMax`
    margin-right: 100px;
    
    figcaption {
      h2 {
        font-size: 3.6rem;
      }
      
      p {
        font-size: 2.8rem;
      }
    }
  `}

  ${breakpoints.tabletMax`
    margin-right: 0;
    margin-bottom: 75px;
  `}


  ${breakpoints.phoneMax`
    margin-bottom: 50px;
    
    figcaption {
      h2 {
        font-size: 2.4rem;
        margin: 30px 0 15px;
      }
      
      p {
        font-size: 1.8rem;
      }
    }
  `}
`;

const StyledRightWrapper = styled.div`
  ${breakpoints.tabletMax`
    text-align: center;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  width: 432px;
  height: 432px;
  border-radius: 50%;

  img,
  picture,
  source {
    border-radius: 50%;
  }

  ${breakpoints.laptopMax`
    width: 342px;
    height: 342px;
  `}

  ${breakpoints.tabletMax`
    width: 100%;
    height: auto;
    max-width: 342px;
  `}
`;

const StyledHeadline = styled.h3`
  font-weight: 700;
  margin: 0 0 100px;
  font-size: 4.8rem;

  ${breakpoints.laptopMax`
    font-size: 3.6rem;
  `}

  ${breakpoints.phoneMax`
    font-size: 2.4rem;
    margin: 0 0 70px;
  `}
`;

const StyledButton = styled(Button)`
  width: fit-content;
  margin-left: auto;
  margin-top: 120px;

  ${breakpoints.computerMin`
    font-size: 2rem;
  `};

  ${breakpoints.tabletMax`
    margin-right: auto;
    margin-top: 80px;
  `}
`;

export const CEO = () => {
  const { file } = useStaticQuery<SimpleFileQuery>(query);
  const { t } = useTranslation("ceo");

  return (
    <StyledWrapper>
      <StyledImageWrapper>
        <StyledImage
          image={getImage(file.childImageSharp.gatsbyImageData)}
          alt={t("name")}
        />
        <figcaption>
          <h2>{t("name")}</h2>
          <p dangerouslySetInnerHTML={{ __html: t("position") }} />
        </figcaption>
      </StyledImageWrapper>
      <StyledRightWrapper>
        <StyledHeadline>{t("title")}</StyledHeadline>
        {(t("quotes", { returnObjects: true }) as string[]).map(
          (text, index) => (
            <Quote secondary={index === 1} key={index}>
              {Html(text)}
            </Quote>
          )
        )}

        <StyledButton
          forwardedAs="a"
          href={t("button-href")}
          secondary
          target="_blank"
          rel="noopener"
        >
          {t("button")}
        </StyledButton>
      </StyledRightWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "avatar" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }
  }
`;

export default CEO;
