import React from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { SectionHeadline } from "@components/atoms/SectionHeadline";
import { stylesWrapper } from "@theme/commonStyles";
import { breakpoints } from "@theme/breakpoints";
import { StaticImage } from "gatsby-plugin-image";

const StyledWrapper = styled.section`
  background: ${({ theme }) => theme.backgroundSecondary};
  margin-top: 150px;
  padding: 150px 0 0;

  ${breakpoints.laptopMax`
    padding: 90px 0 0;
    margin-top: 100px;
  `}

  ${breakpoints.phoneMax`
    padding: 60px 0 0;
  `}
`;

const StyledInnerWrapper = styled.div`
  ${stylesWrapper};
`;

const StyledTitle = styled(SectionHeadline)`
  margin: 0 0 35px;

  ${breakpoints.tabletMax`
    text-align: center;
  `}
`;

const StyledContent = styled.p`
  max-width: 730px;
  line-height: 1.6;
  font-size: 2.2rem;
  margin: 0;

  ${breakpoints.laptopMax`
    font-size: 1.8rem;
    max-width: 600px;
  `}

  ${breakpoints.tabletMax`
    font-size: 1.6rem;
  `}

  ${breakpoints.tabletMax`
    margin: auto;
  `}
`;

const StyledImageWrapper = styled.div`
  width: 100%;
  height: 56.25vw;
  margin-top: 80px;
  position: relative;

  .gatsby-image-wrapper {
    height: 100%;
  }

  ${breakpoints.phoneMax`
    margin-top: 60px;
  `}
`;

export const OurMission = () => {
  const { t } = useTranslation("our-mission");

  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledTitle dangerouslySetInnerHTML={{ __html: t("title") }} />
        <StyledContent>{t("content")}</StyledContent>
      </StyledInnerWrapper>
      <StyledImageWrapper>
        <StaticImage
          src="../../assets/images/our-mission/our-mission.jpg"
          alt=""
          layout="fullWidth"
          objectFit="cover"
          placeholder="blurred"
        />
      </StyledImageWrapper>
    </StyledWrapper>
  );
};
