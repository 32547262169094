import React, { FC, ReactNode } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as QuoteBegin } from "../../icons/quote-begin.svg";
import { ReactComponent as QuoteEnd } from "../../icons/quote-end.svg";
import { breakpoints } from "@theme/breakpoints";

const StyledWrapper = styled.blockquote<{ $isSecondary?: boolean }>`
  width: 100%;
  max-width: 706px;
  padding: 35px 130px;
  font-size: 3.2rem;
  font-weight: 700;
  background: ${({ $isSecondary }) => ($isSecondary ? "#FAFAFA" : "#FFF1E1")};
  position: relative;
  text-align: center;
  border-radius: 999999px;
  margin: 0;

  :first-of-type {
    margin-bottom: 50px;
  }

  span {
    color: ${({ theme }) => theme.primary};
  }

  ${breakpoints.laptopMax`
    font-size: 2.6rem;
    padding: 35px 70px;
  `};

  ${breakpoints.phoneMax`
    font-size: 1.8rem;
    padding: 25px 30px;
    
    :first-of-type {
      margin-bottom: 20px;
    }
  `};
`;

const StyledQuoteMark = styled.span<{ $isSecondary?: boolean }>`
  svg {
    width: 100%;
    height: 100%;
  }

  width: 70px;
  height: 66px;
  position: absolute;
  display: block;

  ${({ $isSecondary }) =>
    $isSecondary
      ? css`
          right: 50px;
          bottom: 0;
          transform: translateY(70%);
        `
      : css`
          left: 50px;
          top: 0;
          transform: translateY(-70%);
        `};

  @media (max-width: 720px) {
    width: 38px;
    height: 38px;
  }
`;

const Quote: FC<Props> = ({ secondary, children }) => (
  <StyledWrapper $isSecondary={secondary}>
    {children}

    <StyledQuoteMark $isSecondary={secondary}>
      {secondary ? <QuoteEnd /> : <QuoteBegin />}
    </StyledQuoteMark>
  </StyledWrapper>
);

interface Props {
  secondary?: boolean;
  children: ReactNode;
}

export default Quote;
